import React from "react";
import { graphql } from "gatsby";
import { Trans } from "gatsby-plugin-react-i18next";
import SEO from "../components/core/Seo";
import BaseLayout from "../components/core/BaseLayout";

import {
  WaveError,
  FeatureDuo,
  Section,
  Content,
  Headline,
  Subline,
  Text,
  Actions,
  Button,
} from "../components/SubComponents";

const NotFoundPage = () => {
  return (
    <BaseLayout>
      <SEO title={"404"} />
      <Section id={"404"}>
        <Content>
          <FeatureDuo verticalOnly>
            <Text verticalOnly>
              <Headline large>
                <Trans>Oh No!</Trans>
              </Headline>
              <Subline>
                <Trans>
                  It seems that you were looking for something that doesn't
                  exist.
                </Trans>
              </Subline>
              <Subline>
                <Trans>
                  No problem, just use the link below to return to my home page,
                  safe and sound.
                </Trans>
              </Subline>
            </Text>
            <Actions>
              <Button ghost={true} to="/" variant="secondary">
                <Trans>Please take me home</Trans>
              </Button>
            </Actions>
          </FeatureDuo>
        </Content>
        <WaveError />
      </Section>
    </BaseLayout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "404"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
